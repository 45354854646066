import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import AlarmsList from './view/alarmsList';
import AlarmsDetail from './view/alarmsDetail';

export default class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/" component={AlarmsList}></Route>
          <Route
            exact
            path="/alarmsDetail/:id"
            component={AlarmsDetail}
          ></Route>
        </Switch>
      </HashRouter>
    );
  }
}
