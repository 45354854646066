import React, { Component } from 'react';
import { InfiniteScroll, PullToRefresh } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';
import { getAlarmsListAPI } from '../../api/api';
import moment from 'moment';
import './index.scss';
import fireImg from "../../assets/images/alarmLight.png";
import QXJZImg from "../../assets/images/QXJZ.png";
import SHJYImg from "../../assets/images/SHJY.png";
export default class alarmsList extends Component {
  state = {
    currentPage: 0,
    alarmsList: [],
    hasMore: true,
    isLoading: false
  };
  // 报警类别
  alarmTypeImage = (type) => {
    if (type == 1) {
      return fireImg
    }
    if (type == 2) {
      return QXJZImg
    }
    if (type == 3) {
      return SHJYImg
    }
    return fireImg
  };
  // 获取警情列表
  getAlarmsList = async () => {
    const res = await getAlarmsListAPI({
      currentPage: this.state.currentPage,
      pageSize: 15,
    });
    if (res.code !== 200) return;
    this.setState(
      {
        alarmsList: [...res.data.list, ...this.state.alarmsList],
        isLoading: false
      },
      () => {
        if (res.data.total == this.state.alarmsList.length) {
          this.setState({
            hasMore: false,
          });
        }
      }
    );
  };
  goDetail = (item) => {
    this.props.history.push('/alarmsDetail/' + item.id);
  };
  // 上拉加载更多
  loadMore = () => {
    if (this.state.isLoading) {
      return;
    }
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
        isLoading: true
      },
      () => {
        this.getAlarmsList();
      }
    );
  };
  // 下拉刷新
  async pullToRefreshFn() {
    // await sleep(1000);
    this.setState(
      {
        currentPage: 0,
        alarmsList: [],
        hasMore: true,
      }
      // () => {
      //   this.getAlarmsList();
      // }
    );
  }
  componentDidMount() {
    // this.getAlarmsList();
    document.title = '联勤联动';
  }
  render() {
    return (
      <div className="alarmsList-view">
        <PullToRefresh
          onRefresh={() => {
            this.pullToRefreshFn();
          }}
        >
          <div className="alarms-list">
            {this.state.alarmsList.map((item) => {
              return (
                <div
                  key={item.id}
                  className="alarms-item"
                  onClick={() => {
                    this.goDetail(item);
                  }}
                >
                  <div className="left-img">
                    <img
                      src={this.alarmTypeImage(item.caseType)}
                      alt=""
                    />
                  </div>
                  <div className="text">
                    <h5>{item.crimeScence}</h5>
                    <p>{moment(item.creatTime).format('YYYY年M月D日HH:mm')}</p>
                  </div>
                </div>
              );
            })}
            <InfiniteScroll
              loadMore={this.loadMore}
              hasMore={this.state.hasMore}
            />
          </div>
        </PullToRefresh>
      </div>
    );
  }
}
