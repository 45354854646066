/*
 * @Author: trevor
 * @Date: 2023-11-13 15:17:15
 * @LastEditors: trevor
 * @LastEditTime: 2023-11-13 17:39:01
 * @Description: 
 */
// react18不再支持ReactDom.render，新写法
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import VConsole from 'vconsole';


// if(process.env.NODE_ENV==='development'){
//   new VConsole();
// }

// 设置 rem 函数
function setRem() {
  document.documentElement.style.fontSize = '37.5px';
}
// 初始化
setRem();

// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem();
  console.log();
};
const root = createRoot(document.querySelector('#root'));
root.render(<App></App>);
