import request from './request';
/**
 * 获取警情列表
 * @param {*}
 * @returns
 */
export function getAlarmsListAPI(params) {
  return request({
    url: '/alarm/list',
    method: 'GET',
    params,
  });
}
export function getAlarmsDetailAPI(params) {
  return request({
    url: '/alarm/detail',
    method: 'GET',
    params,
  });
}
