import React, { Component } from 'react';
import './index.scss';
import moment from 'moment';
import { getAlarmsDetailAPI } from '../../api/api';
const { AMap } = window;
export default class alarmsDetail extends Component {
  state = {
    alarmsDetail: {},
    map: null,
  };
    // 报警类别
  alarmTypeText = (type) => {
    if (type == 1) {
      return '火灾'
    }
    if (type == 2) {
      return '抢险救援'
    }
    if (type == 3) {
      return '社会救助'
    }
    return '火灾'
  };
  getAlarmsDetail = async () => {
    // console.log(this.props.match.params.id);
    const res = await getAlarmsDetailAPI({
      alarmId: this.props.match.params.id,
    });
    // console.log(res);
    if (res.code !== 200) return;
    this.setState({
      alarmsDetail: res.data,
    });
    this.state.map.setCenter([res.data.ycoord, res.data.xcoord]);
    var marker = new AMap.Marker({
      position: new AMap.LngLat(res.data.ycoord, res.data.xcoord),
      offset: new AMap.Pixel(-8, -34),
      icon: require('../../assets/images/zuobiaodian.png'), // 添加 Icon 图标 URL
      title: '',
    });
    this.state.map.add(marker);
  };
  initMap = () => {
    // console.log(this.state.alarmsDetail);
    const map = new AMap.Map('mapContainer', {
      zoom: 13, //级别
      center: [116.397428, 39.90923], //中心点坐标
    });

    this.setState({
      map: map,
    });
  };
  async componentDidMount() {
    document.title = '联勤联动';
    await this.initMap();
    this.getAlarmsDetail();
  }
  render() {
    const { alarmsDetail } = this.state;
    return (
      <div className="alarmsDetail-view">
        <div className="main">
          <h4>报警详情</h4>
          <div className="detail-box">
            <div className="detail-item">
              <h6>报警时间</h6>
              <p>
                {moment(alarmsDetail.creatTime).format('YYYY年M月D日HH:mm')}
              </p>
            </div>
            <div className="detail-item">
              <h6>案发地址</h6>
              <p>{alarmsDetail.crimeScence}</p>
            </div>
            <div className="detail-item">
              <h6>所属街道</h6>
              <p>{alarmsDetail.belongStreetName}</p>
            </div>
            <div className="detail-item">
              <h6>案件类型</h6>
              <p>{this.alarmTypeText(alarmsDetail.caseType)}</p>
            </div>
            <div className="detail-item">
              <h6>报警人</h6>
              <p>{alarmsDetail.alarmPeople}</p>
            </div>
            <div className="detail-item">
              <h6>报警人电话</h6>
              <p>{alarmsDetail.alarmPeoplePhone}</p>
            </div>
          </div>
          <div className="map" id="mapContainer">
            {/* <img src={require('../../assets/images/zuobiaodian.png')} alt="" /> */}
          </div>
        </div>
      </div>
    );
  }
}
