/*
 * @Author: 邹久荣 zoujr@rayeye.cn
 * @Date: 2022-10-11 15:23:03
 * @LastEditors: 邹久荣 zoujr@rayeye.cn
 * @LastEditTime: 2022-10-13 14:24:11
 * @FilePath: \vue-parkplatform-manage\src\service\axios.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios';
import { Toast } from 'antd-mobile';
// axios.defaults.baseURL = 'http://192.168.10.175:8002';
// axios.defaults.baseURL = 'http://192.168.10.85/api';
// axios.defaults.baseURL = 'https://weixin.484e.com/api'; // 测试
axios.defaults.baseURL = baseConfig().SERVERROOT; // 生产

axios.defaults.withCredentials = true;
axios.defaults.timeout = 20000;
axios.defaults.headers['Content-Type'] = 'application/json';
// 请求拦截器

axios.interceptors.request.use((config) => {
  // 在发送请求之前如果user.token存在就添加请求头
  config.headers.dev = 'pc';
  // if (userStore.token) {
  //   config.headers['x-auth-token'] = userStore.token;
  // }
  return config;
});
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    //关闭loadingBar
    if (response.data.code && response.data.code !== 200) {
      //特殊code处理
      Toast.show({
        icon: 'fail',
        content: response.data.message,
      });
    }
    // 返回data
    return response.data;
  },
  async function (error) {
    Toast.show({
      icon: 'fail',
      content: error.message,
    });
    return Promise.reject(error);
  }
);

export default axios;
